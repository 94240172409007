import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import TagListing from "../components/Language/TagListing";

export default function DemandTemplate({ pageContext, data }) {
  const { demand } = pageContext;
  const languageEdges = data.allLanguagesJson.edges;
  return (
    <Layout>
      <Helmet
        title={`Languages with "${demand}" demand | ${config.siteTitle}`}
      />
      <TagListing
        tag={demand}
        category="Demand"
        allLanguagesJson={data.allLanguagesJson}
        languageEdges={languageEdges}
        description="Developers are in high demand these days, but which developers exactly? Developers with experience working with an employer's technologies are given preference, so the more popular languages/frameworks you know, the better your chances."
      />
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query DemandPage($demand: String) {
    allLanguagesJson(
      limit: 1000
      filter: { demand: { value: { eq: $demand } } }
    ) {
      totalCount
      edges {
        node {
          slug
          name
          description
          used_for
          getting_help {
            value
          }
          pay {
            value
          }
          barrier_to_entry {
            value
          }
          demand {
            value
          }
        }
      }
    }
  }
`;
